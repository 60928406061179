var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "balance beauty-scroll" }, [
    !_vm.showImport
      ? _c(
          "div",
          [
            _c("appForm", {
              staticClass: "appform",
              attrs: {
                "to-list": _vm.formList,
                "search-placeholder": "请搜索姓名/工号",
                "screen-roster-list": _vm.screenRosterList,
              },
              on: {
                getSearchCallback: _vm.getSearchCallback,
                screenRosterParams: _vm.screenRosterParams,
                exportFileList: _vm.exportFileList,
              },
            }),
            _c("custom-table", {
              attrs: {
                columns: _vm.columns,
                "data-source": _vm.dataSource,
                "row-key": "key",
                pagination: {
                  current: _vm.pager.pageNum,
                  pageSize: _vm.pager.pageSize,
                  total: _vm.total,
                  showTotal: function (total) {
                    return "共 " + total + " 条数据"
                  },
                  showQuickJumper: true,
                  showSizeChanger: true,
                },
              },
              on: { change: _vm.loadDataList },
              scopedSlots: _vm._u(
                [
                  {
                    key: "index",
                    fn: function (ref) {
                      var scope = ref.scope
                      return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
                    },
                  },
                  {
                    key: "staffName",
                    fn: function (ref) {
                      var scope = ref.scope
                      return [
                        _c("CbTableAvater", {
                          attrs: {
                            "table-name": scope.record.staffName,
                            "on-job-id": scope.record.onJobId,
                            "staff-id": scope.record.staffId,
                            title: scope.record.staffName,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "operation",
                    fn: function (ref) {
                      var scope = ref.scope
                      return [
                        _c("div", { staticClass: "flex-align-center" }, [
                          _c(
                            "div",
                            { staticClass: "more-handle" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDrawer(scope.record)
                                    },
                                  },
                                },
                                [_vm._v(" 查看记录 ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                436797400
              ),
            }),
            _c(
              "Drawer",
              {
                attrs: {
                  "is-mask": "",
                  "display-btn": "",
                  visible: _vm.detailVisible,
                  title: _vm.title,
                  placement: "right",
                  size: "small",
                },
                on: { close: _vm.closeDetailDrawer },
              },
              [
                _c("div", { staticClass: "head" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.holidayList.totalGrantLeave)),
                      ]),
                      _vm._v("小时"),
                    ]),
                    _c("p", [_vm._v("累计发放年假")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.holidayList.totalDeductLeave)),
                      ]),
                      _vm._v("小时"),
                    ]),
                    _c("p", [_vm._v("累计扣减年假")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.holidayList.leaveBalanceHours)),
                      ]),
                      _vm._v("小时"),
                    ]),
                    _c("p", [_vm._v("剩余年假")]),
                  ]),
                  _c("br"),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.holidayList.totalGrantInLieu)),
                      ]),
                      _vm._v("小时"),
                    ]),
                    _c("p", [_vm._v("累计发放调休假")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.holidayList.totalDeductInLieu)),
                      ]),
                      _vm._v("小时"),
                    ]),
                    _c("p", [_vm._v("累计扣减调休假")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.holidayList.inLieuBalanceHours)),
                      ]),
                      _vm._v("小时"),
                    ]),
                    _c("p", [_vm._v("剩余调休假")]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "holidayRecords" },
                  [
                    _c(
                      "div",
                      { staticClass: "holidayRecords-head" },
                      [
                        _c(
                          "BlockTitle",
                          { attrs: { title: "假期记录（小时）" } },
                          [
                            _c(
                              "template",
                              { slot: "right" },
                              [
                                _c("a-select", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    options: _vm.holiday,
                                    "default-value": "3",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-timeline",
                      { staticClass: "holidayRecords-timeline" },
                      _vm._l(_vm.holidayList.records, function (item) {
                        return _c(
                          "a-timeline-item",
                          { key: item.vacationTypeIdvacationTypeId },
                          [
                            _c("a-row", [
                              _c("p", [_vm._v(_vm._s(item.createTime))]),
                              _c("p", [
                                _c("span", { staticClass: "orange" }, [
                                  _vm._v(_vm._s(item.adjustBalanceAmount)),
                                ]),
                                _vm._v("小时"),
                              ]),
                            ]),
                            _c("a-row", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(item.vacationTypeName) +
                                    " - " +
                                    _vm._s(
                                      _vm.getCodeContent(
                                        item.balanceChangeReason,
                                        _vm.vacation_balance_change_reason
                                      )
                                    )
                                ),
                              ]),
                            ]),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }